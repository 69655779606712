<template>
  <v-app>
    <v-main>
      <keep-alive exclude="ArticleDetails">
        <router-view class="Router"/>
      </keep-alive>
    </v-main>
  </v-app>
</template>

<script>
import defaultImage from '../public/image/default.png'
export default {
  name: 'App',
  components: {

  },
  data () {
    return {
      
    }
  },
  methods: {
    // http://sadmin.chonghui.net/wx_sign
    
    
  },
  created(){
    if (!window.h5sdk) {
      const body = document.body
      body.innerHTML = ''
      const img = document.createElement('img')
      img.setAttribute('src', defaultImage)
      img.setAttribute('class', 'default-img')
      const div = document.createElement('div')
      div.setAttribute('class', 'default-tips')
      div.innerHTML = '请在飞书移动客户端打开链接'
      body.appendChild(img)
      body.appendChild(div)
    }
  }
};
</script>

<style lang="less">
  #app {
    .Router {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      backface-visibility: hidden;
      overflow: hidden;
    }

    

    /* 适配iphoneX iphoneXS */
    @media screen and (device-width:375px) and (device-height:812px){
        // .footer {
        //   margin-bottom:34px;
        // }
        .v-item-group.v-bottom-navigation--fixed {
          padding-bottom:25px;
          height: 90px;
        }
        // .v-item-group.v-bottom-navigation--fixed {
        //   bottom: 34px;
        // }
    }
    /* 适配iphoneXR iphoneXSMax */
    @media screen and (device-width:414px) and (device-height:896px){
        // .footer {
        //   margin-bottom:34px;
        // }
        .v-item-group.v-bottom-navigation--fixed {
          padding-bottom:25px;
          height: 90px;
        }
        // .v-item-group.v-bottom-navigation--fixed {
        //   bottom: 34px;
        // }
    }
  }
</style>